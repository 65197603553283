




































































import { Component, Vue } from 'vue-property-decorator'
import Tinymce from '@/components/Tinymce/index.vue'
import api from '@/api'

import {quillEditor} from 'vue-quill-editor' //调用编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {imageUrl, imageUrl2} from '@/utils/common'

import ImageResize from 'quill-image-resize-module';
import * as Quill from "quill";

Quill.register('modules/imageResize', ImageResize);


@Component({
  name: 'TinymceDemo',
  components: {
    Tinymce,
    quillEditor
  }
})
export default class extends Vue {
  baseUrl = imageUrl2;
  info: any = {
    companyName: '',
    companyAddr: '',
    companyText: '',
    productText: ''
  };
  text = '上传配图';

  dataAuth: any = {};
  model: any = {
    OSSAccessKeyId: "",
    signature: "",
    policy: "",
    key: "",
    callback: "",
    file: "",
    success_action_status: ''
  };

  handleAvatarSuccess(res: any, file: any) {
    console.log('上传成功', res.data);
    this.handleSuccess(res.data, 1)
  }

  handleAvatarSuccess2(res: any, file: any) {
    console.log('上传成功', res.data);
    this.handleSuccess(res.data, 2)
  }

  beforeAvatarUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.$message.error("上传大小不能超过 2MB!");
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _that = this;
    return new Promise(function (resolve, reject) {
      api
              .post("J31112", {
                fileName: file.name
              })
              .then((data) => {
                console.log('上传中');
                _that.dataAuth['OSSAccessKeyId'] = data.OSSAccessKeyId;
                _that.dataAuth['signature'] = data.signature;
                _that.dataAuth['policy'] = data.policy;
                _that.dataAuth['key'] = data.key;
                _that.dataAuth['callback'] = data.callback;
                _that.dataAuth['success_action_status'] = data.success_action_status;
                resolve(true);
              })
              .catch(() => {
                reject(false);
              });
    });
  }

  file: any = {};

  editorOption = {
    placeholder: '在这里输入公司介绍',
    modules: {
      imageResize: {
        displayStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white'
        },
        modules: ['Resize', 'DisplaySize', 'Toolbar']
      },
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], //  加粗、倾斜、下划线、删除线
        ['blockquote', 'code-block'],// 引用代码块
        [{'header': 1}, {'header': 2}], // 标题，键值对应的形式，1，2 表示字体大小
        [{'list': 'ordered'}, {'list': 'bullet'}], // 列表
        [{'script': 'sub'}, {'script': 'super'}], // 上下标
        [{'indent': -1}, {'indent': +1}], // 缩进
        [{'direction': 'rtl'}], // 文本方向
        [{'size': ['small', false, 'large', 'huge']}], // 字体大小
        [{'header': [1, 2, 3, 4, 5, 6, false]}], // 几级标题
        [{'color': []}, {'background': []}], // 字体颜色，字体背景颜色
        [{'font': []}], // 字体
        [{'align': []}], // 对齐方式
        ['clean'], //清除
      ]
    }
  };

  editorOption2 = {
    placeholder: '在这里输入产品介绍',
    modules: {
      imageResize: {
        displayStyles: {
          backgroundColor: 'black',
          border: 'none',
          color: 'white'
        },
        modules: ['Resize', 'DisplaySize', 'Toolbar']
      },
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], //  加粗、倾斜、下划线、删除线
        ['blockquote', 'code-block'],// 引用代码块
        [{'header': 1}, {'header': 2}], // 标题，键值对应的形式，1，2 表示字体大小
        [{'list': 'ordered'}, {'list': 'bullet'}], // 列表
        [{'script': 'sub'}, {'script': 'super'}], // 上下标
        [{'indent': -1}, {'indent': +1}], // 缩进
        [{'direction': 'rtl'}], // 文本方向
        [{'size': ['small', false, 'large', 'huge']}], // 字体大小
        [{'header': [1, 2, 3, 4, 5, 6, false]}], // 几级标题
        [{'color': []}, {'background': []}], // 字体颜色，字体背景颜色
        [{'font': []}], // 字体
        [{'align': []}], // 对齐方式
        ['clean'], //清除
      ]
    }
  };

  focus(val: any) {
    if(val == 1) {
      (this.$refs.myQuillEditor as any).quill.focus();
      return
    }
    (this.$refs.myQuillEditor2 as any).quill.focus();
  }

  handleSuccess(res: any, val: any) {

    // 获取富文本组件实例
    let quill;
    if(val == 1) {
      quill = (this.$refs.myQuillEditor as any).quill;
    } else {
      quill = (this.$refs.myQuillEditor2 as any).quill;
    }


    // 如果上传成功
    if (res) {
      let NODE_ENV = this.baseUrl
      // 获取光标所在位置
      let length = quill.getSelection().index;
      // 插入图片，res为服务器返回的图片链接地址
      quill.insertEmbed(length, 'image', NODE_ENV + res)
      // 调整光标到最后
      quill.setSelection(length + 1)
    } else {
      // 提示信息，需引入Message
      this.$message.error('图片插入失败')
    }
  }

  weight = '';

  sure() {
    // this.info.weight = Number(this.weight)
    if (this.id) {
      this.info.id = this.id
    }
    api
            .post(this.id ? 'J31104' : 'J31103', this.info)
            .then(res => {
              this.$message.success('操作成功')
              this.info = {
                companyName: '',
                companyAddr: '',
                companyText: '',
                productText: ''
              }
              this.$router.push({
                name: 'apple_qiye_api'
              })
            })
  }

  read(id: any) {
    api
            .post('J31102', {id: id})
            .then(res => {
              this.info = res
            })
  }

  get editor() {
    return (this.$refs.myQuillEditor as any).quill
  }
  get editor2() {
    return (this.$refs.myQuillEditor2 as any).quill
  }

  informationCode = '';

  id = ''
  activated() {
    this.info = {
      companyName: '',
      companyAddr: '',
      companyText: '',
      productText: ''
    }
    this.id = ''
    let {id} = this.$route.query

    if (id) {
      this.id = id as string
      this.read(id)
    }
  }
};
